/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Podéis ver a la derecha un nuevo banner que he añadido, en contra de las patentes en el software, este tipo de patentes impiden el desarrollo de software por pequeñas empresas y facilitan el monopolio, en general estoy en contra de las patentes de todo tipo, pero en el software son especialmente dañinas por eso estoy de acuerdo con la Free Software Fundation de que deben ser eliminadas, en especial en Europa donde aún no han tenido tiempo de hacer tanto daño como en Estados Unidos."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
